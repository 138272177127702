import React, { useState, useEffect } from 'react';
import './Refund.scss';
import { Container } from 'react-bootstrap';
import Logo from '../../Assets/PNGs/edain-pay.png';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as ErrorImg } from "../../Assets/SVGs/undraw_404.svg";
import HashLoader from "react-spinners/HashLoader";
import { toast } from "react-toastify";
import RefundModal from '../../Components/RefundModal/RefundModal';

const Refund = () => {
  const [loading, setLoading] = useState(true);
  const [sessionExists, setSessionExists] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [data, setData] = useState(null);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [refundWallet, setRefundWallet] = useState("");
  const [show, setShow] = useState(false);

  const [searchParams] = useSearchParams();
  const erc20 = new RegExp(/^0x[a-fA-F0-9]{40}$/);

  useEffect(() => {
    const refundId = searchParams.get("refundId");
    const refundCode = searchParams.get("refundCode");

    if (!refundId || !refundCode) {
      setSessionExists(false);
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/edainpay/sessions/refund/public/${refundId}/${refundCode}`);
        if (response.status === 200) {
          setSessionExists(true);
          setData(response.data);
        }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 404:
              setSessionExists(false);
              return;
            default:
              setServerError(true);
              return;
          }
        }
      } finally {
        setLoading(false);
      }
    }

    fetchData();

  }, [searchParams]);

  const openModal = () => {

    if (!erc20.test(refundWallet)) {
      toast.error("Please enter a valid wallet address", { toastId: "refund-modal-toast" });
    } else {
      setShow(true);
    }
  };
  const closeModal = () => setShow(false);

  const handleRefund = async () => {
    const refundId = searchParams.get("refundId");
    const refundCode = searchParams.get("refundCode");

    try {
      setLoadingRefund(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/edainpay/sessions/refund/public/${refundId}/${refundCode}`, {
        address: refundWallet
      });
      if (response.status === 200) {
        toast.success("Your refund is now being processed. You will receive an email when it is complete.", { toastId: "refund-success" });
        closeModal();
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      }
    } catch (err) {
      toast.error("There was an error processing your refund. Please try again later.", { toastId: "refund-error" });
    } finally {
      setLoadingRefund(false);
    }
  };

  let toRender;

  if (loading) {
    toRender = (
      <HashLoader size={100} color={"#45C2D5"} loading={loading} />
    );
  } else if (serverError) {
    toRender = (
      <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
        <p className="font-pnsb font-size-24">Oops.. There was an error!</p>
        <ErrorImg className="error-img" />
      </div>
    )
  } else if (!sessionExists) {
    toRender = (
      <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
        <p className="font-pnsb font-size-24">Session does not exist.</p>
        <ErrorImg className="error-img" />
      </div>
    )
  } else {
    if (data) {
      if (data.status === "pending") {
        toRender = (
          <div className="d-flex flex-column justify-content-between h-100 w-100">
            <div>
              <p className="font-pnsb font-blue mb-0">Amount:</p>
              <div className="d-flex flex-column amount">
                <p className="font-pnr mb-2 amount-num">{data?.amount.toFixed(18)} {data?.currency}</p>
              </div>
              <div className="d-flex flex-column amount">
                <p className="font-pnsb font-blue mb-0">Refund Id:</p>
                <p className="font-pnr mb-2">{data?.id}</p>
              </div>
              <input className="primary-input" type="text" placeholder="Enter refund wallet address" value={refundWallet} onChange={(event) => setRefundWallet(event.target.value)} />
              <div className="w-100 d-flex justify-content-center">
                <button disabled={loadingRefund} onClick={openModal} className="mt-3 epay-blue-button">Refund</button>
              </div>
              <p className="font-pnl font-size-14 font-grey mb-0 mt-5">*Please save the refund id in case there are any issues with your refund</p>
            </div>

            <div className="divider-container">
              <b className="hr anim"></b>
              <img src={Logo} className="logo mb-2" alt="edain-pay" />
              <div className="d-flex flex-column mt-1">
                <p onClick={() => window.open("https://edain.ai/privacy-policy", "_blank")} className="font-pnb mb-1 cursor-pointer">Privacy Policy</p>
                <p onClick={() => window.open("https://edain.ai/terms", "_blank")} className="font-pnb cursor-pointer mb-0">Terms and Conditions</p>
              </div>
            </div>

            <RefundModal
              show={show}
              onHide={closeModal}
              walletAddress={refundWallet}
              handleRefund={handleRefund}
              amount={data?.amount}
              currency={data?.currency}
              loadingRefund={loadingRefund}
            />
          </div>
        )
      } else if (data.status === "processing") {
        toRender = (
          <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
            <p className="font-pnsb"> Your refund is being processed. <br />It shouldn't take longer than an hour to process.<br /> If you do not receive your refund, please contact us.</p>
            <ErrorImg className="error-img" />
          </div>
        )
      } else if (data.status === "refunded") {
        toRender = (
          <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
            <p className="font-pnsb"> Thank you for using Edain Pay. <br /> Your refund has been completed. <br />Please check the wallet and if you did not receive the funds contact the support team for further information.</p>
            <ErrorImg className="error-img" />
          </div>
        )
      } else {
        toRender = (
          <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
            <p className="font-pnsb"> We are deeply sorry for the issue you have experienced. There was a problem with the refund and we would like to help you out with this. Please contact the support team for further information.</p>
            <ErrorImg className="error-img" />
          </div>
        )
      }
    } else {
      toRender = (
        <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
          <p className="font-pnsb font-size-24">Oops.. There was an error!</p>
          <ErrorImg className="error-img" />
        </div>
      );
    }
  }

  return (
    <Container>
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <div className="main-box refund-page my-3 p-5 d-flex flex-column align-items-center align-items-center h-100">
          <div className="transaction-status-header pb-4 d-flex justify-content-center align-items-center">
            <p className="font-pnsb font-size-24 text-align-center">
              Refund request
            </p>
          </div>
          {toRender}
        </div>
      </div>
    </Container>
  );
};

export default Refund;