import React, { useState, useEffect } from 'react';
import './Payment.scss';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Logo from '../../Assets/PNGs/edain-pay.png';
import axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import { ReactComponent as ErrorImg } from "../../Assets/SVGs/undraw_404.svg";
import Countdown from 'react-countdown';
import QRCode from "react-qr-code";
import { HiOutlineClipboardCopy as CopyToClipboardIcon } from "react-icons/hi";
import { IoArrowBackCircleOutline as ArrowIcon } from "react-icons/io5";
import { toast } from 'react-toastify';
import { routes } from "../../Utils/routes";
import Fade from "react-reveal/Fade";
import { ethers } from "ethers";
import { config } from '../../Utils/config';
import { ImInfo } from "react-icons/im";

const Payment = () => {
    const [loading, setLoading] = useState(true);
    const [sessionExists, setSessionExists] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [data, setData] = useState(null);
    const [merchantView, setMerchantView] = useState(true);

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const checkoutId = searchParams.get("checkoutId");

        if (!checkoutId) {
            setSessionExists(false);
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/edainpay/sessions/checkout/public/${checkoutId}`);
                if (response.status === 200) {
                    setSessionExists(true);
                    setData(response.data);
                    if (data && data.status === "unpaid" && response.data.status === "paid") {
                        toast.success("Payment completed successfully, redirecting in progress...");
                        setTimeout(() => {
                            window.location.href = data.success_url;
                        }, 3000);
                    } else if (!data && response.data.status === "paid") {
                        toast.success("Payment completed successfully, redirecting in progress...");
                        setTimeout(() => {
                            navigate(routes.status + "?checkoutId=" + checkoutId);
                        }, 3000);
                    }
                }
            } catch (err) {
                if (err.response) {
                    switch (err.response.status) {
                        case 404:
                            setSessionExists(false);
                            return;
                        default:
                            setServerError(true);
                            return;
                    }
                }
            } finally {
                setLoading(false);
            }
        }

        if (sessionExists && data && data.status === "unpaid") {
            setInterval(() => {
                fetchData();
            }, 15000);
        } else {
            fetchData();
        }

    }, [searchParams, sessionExists]);

    useEffect(() => {
        const ABI = [
            // Some details about the token
            'function name() view returns (string)',
            'function symbol() view returns (string)',

            // Get the account balance
            'function balanceOf(address) view returns (uint)',

            // Send some of your tokens to someone else
            'function transfer(address to, uint amount)',

            // An event triggered whenever anyone transfers to someone else
            'event Transfer(address indexed from, address indexed to, uint amount)',
        ];

        const metamaskPayment = async () => {
            const checkoutId = searchParams.get("checkoutId");

            try {
                if (!window.ethereum) {
                    return;
                }

                if (localStorage.getItem("checkoutId") === checkoutId) {
                    return;
                }

                // send transaction using metamask and ether 
                await window.ethereum.send("eth_requestAccounts");
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const network = await provider.getNetwork();

                if (network.chainId !== Number(config[data.currency_2].chain_id) || network.name !== config[data.currency_2].network_name) {
                    return toast.warning("Please connect to the Xiden mainnet before sending funds", { toastId: "mainnet" });
                }

                const signer = provider.getSigner();
                const contract = new ethers.Contract(config[data.currency_2].contract_address, ABI, signer);

                await contract.transfer(
                    data.wallet?.address,
                    ethers.utils.parseUnits(data.amount_left.toString(), 18),
                    {
                        gasPrice: ethers.utils.parseUnits('1', 'gwei'),
                    }
                );

                localStorage.setItem("checkoutId", checkoutId);

            } catch (err) {
                console.log(err);
            }
        }

        if (data?.status === "unpaid" && new Date() < new Date(data?.expires_at_soft)) {
            metamaskPayment();
        };
    }, [data?.status, data?.amount_left, data?.wallet?.address, data?.currency_2, searchParams, data?.expires_at_soft]);

    const renderer = ({ hours, minutes, seconds }) => {
        return <span>{hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</span>;
    };

    const copyToClipboardTP = <Tooltip>Copy wallet address to clipboard.</Tooltip>;

    const handleCopyToClipboard = (wallet) => {
        navigator.clipboard.writeText(wallet);
        toast.info("Wallet address copied to clipboard", { toastId: "clipboard" });
    }

    const handleMerchantView = (value) => {
        setMerchantView(value);
    }

    const handleGoBack = () => {
        window.location.href = data.cancel_url;
    }

    let toRender;

    if (loading) {
        toRender = (
            <HashLoader size={100} color={"#45C2D5"} loading={loading} />
        );
    } else if (serverError) {
        toRender = (
            <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
                <p className="font-pnsb font-size-24">Oops.. There was an error!</p>
                <ErrorImg className="error-img" />
            </div>
        )
    } else if (!sessionExists) {
        toRender = (
            <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
                <p className="font-pnsb font-size-24">Session does not exist.</p>
                <ErrorImg className="error-img" />
            </div>
        )
    } else {
        if (data) {
            if (data.status === "expired_hard" || data.status === "expired_soft" || data.status === "aborted" || new Date() >= new Date(data.expires_at_soft)) {
                toRender = (
                    <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
                        <p className="font-pnsb font-size-24">Session expired.</p>
                        <ErrorImg className="error-img" />
                    </div>
                );
            } else {
                toRender = (
                    <Container fluid>
                        <Row lg={2} md={2} sm={1} xs={1}>
                            <Col className="h-100">
                                <div>
                                    <div className="merchant-view mb-4 cursor-pointer">
                                        <Fade duration={50} spy={merchantView}>
                                            {merchantView ? (
                                                <h5 onMouseEnter={() => handleMerchantView(false)} className="font-pnr mb-0">{data.merchant?.name || "Edain Pay"}</h5>
                                            ) : (
                                                <div onClick={handleGoBack} onMouseLeave={() => handleMerchantView(true)} className="d-flex align-items-center">
                                                    <ArrowIcon className="arrow-icon me-2" />
                                                    <h5 className="font-pnr mb-0">Back</h5>
                                                </div>
                                            )}
                                        </Fade>
                                    </div>

                                    <p className="font-pnsb font-blue mb-0">Amount remaining:</p>
                                    <div className="d-flex flex-column amount">
                                        <p className="font-pnr mb-0 amount-num">{data.amount_left < 0 ? Number(0).toFixed(18) : data.amount_left.toFixed(18)}</p>
                                        <p className="font-pnr">{data.currency_2}</p>
                                    </div>
                                </div>
                                <div className="divider-container-lg">
                                    <img src={Logo} className="logo" alt="edain-pay" />
                                    <b className="hr anim"></b>
                                    <div className="d-flex align-items-center gap-2">
                                        <p onClick={() => window.open("https://edain.ai/privacy-policy", "_blank")} className="font-pnb cursor-pointer">Privacy Policy</p>
                                        <p className="font-pnb">|</p>
                                        <p onClick={() => window.open("https://edain.ai/terms", "_blank")} className="font-pnb cursor-pointer">Terms and Conditions</p>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="qr-container mb-3">
                                    <QRCode size={150} value={data.wallet?.address} />
                                </div>

                                <p className="font-pnsb font-blue mb-0">Address:</p>
                                <OverlayTrigger placement="top" overlay={copyToClipboardTP}>
                                    <div onClick={() => handleCopyToClipboard(data.wallet?.address)} className="cursor-pointer d-flex mb-3">
                                        <input readOnly className="primary-input input-border-radius" type="text" value={data.wallet?.address} />
                                        <div className="copy-to-clipboard-extension"><CopyToClipboardIcon /></div>
                                    </div>
                                </OverlayTrigger>

                                {data.status === "paid" ? (
                                    <input readOnly className="w-100 primary-input mb-3 success-color" type="text" value="Transaction completed" />
                                ) : (
                                    <div className="mb-3">
                                        <p className="font-pnsb font-blue mb-0">Time left:</p>
                                        <Countdown date={data.expires_at_soft} renderer={renderer} />
                                    </div>
                                )}

                                <p className="font-pnsb font-blue mb-0">Payment ID:</p>
                                <input readOnly className="w-100 primary-input mb-3" type="text" value={data.id} />

                                <p className="font-pnsb font-blue mb-0">Verification Code:</p>
                                <input readOnly className="w-100 primary-input mb-3" type="text" value={data.client_reference_id || ""} />
                                <p className="font-pnl font-size-14 font-grey">(save in case there are any issues with your payment)</p>

                                <p className="font-pnr"><ImInfo /> Please do not close this window after payment.</p>

                                <div className="divider-container-sm">
                                    <b className="hr anim"></b>
                                    <img src={Logo} className="logo mb-2" alt="edain-pay" />
                                    <div className="d-flex flex-column mt-1">
                                        <p onClick={() => window.open("https://edain.ai/privacy-policy", "_blank")} className="font-pnb mb-1 cursor-pointer">Privacy Policy</p>
                                        <p onClick={() => window.open("https://edain.ai/terms", "_blank")} className="font-pnb cursor-pointer">Terms and Conditions</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container >
                )
            }
        } else {
            toRender = (
                <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
                    <p className="font-pnsb font-size-24">Oops.. There was an error!</p>
                    <ErrorImg className="error-img" />
                </div>
            );
        }
    }

    return (
        <Container>
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <div className="main-box payment my-3 p-5 d-flex justify-content-center align-items-center h-100">
                    {toRender}
                </div>
            </div>
        </Container>
    );
};

export default Payment;