import React, { useState, useEffect } from 'react';
import './TransactionStatus.scss';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Logo from '../../Assets/PNGs/edain-pay.png';
import axios from "axios";
import { useSearchParams } from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import { ReactComponent as ErrorImg } from "../../Assets/SVGs/undraw_404.svg";
import Countdown from 'react-countdown';
import QRCode from "react-qr-code";
import { HiOutlineClipboardCopy as CopyToClipboardIcon } from "react-icons/hi";
import { toast } from 'react-toastify';

const TransactionStatus = () => {
    const [loading, setLoading] = useState(true);
    const [sessionExists, setSessionExists] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [data, setData] = useState(null);

    const [searchParams] = useSearchParams();

    useEffect(() => {
        const checkoutId = searchParams.get("checkoutId");

        if (!checkoutId) {
            setSessionExists(false);
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/edainpay/sessions/checkout/public/${checkoutId}`);
                if (response.status === 200) {
                    setSessionExists(true);
                    setData(response.data);
                }
            } catch (err) {
                if (err.response) {
                    switch (err.response.status) {
                        case 404:
                            setSessionExists(false);
                            return;
                        default:
                            setServerError(true);
                            return;
                    }
                }
            } finally {
                setLoading(false);
            }
        }

        if (sessionExists && data && data.status === "unpaid") {
            setInterval(() => {
                fetchData();
            }, 60000);
        } else {
            fetchData();
        }

    }, [searchParams, sessionExists]);

    const renderer = ({ hours, minutes, seconds }) => {
        return <span>{hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</span>;
    };

    const copyToClipboardTP = <Tooltip>Copy wallet address to clipboard.</Tooltip>;

    const handleCopyToClipboard = (wallet) => {
        navigator.clipboard.writeText(wallet);
        toast.info("Wallet address copied to clipboard", { toastId: "clipboard" });
    }

    const displayStatus = () => {
        switch (data?.status) {
            case "expired_soft":
            case "expired_hard":
                return "Expired";
            case "paid":
                return "Paid";
            case "unpaid":
                return "Unpaid";
            default:
                return "Unknown";
        }
    }

    let toRender;

    if (loading) {
        toRender = (
            <HashLoader size={100} color={"#45C2D5"} loading={loading} />
        );
    } else if (serverError) {
        toRender = (
            <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
                <p className="font-pnsb font-size-24">Oops.. There was an error!</p>
                <ErrorImg className="error-img" />
            </div>
        )
    } else if (!sessionExists) {
        toRender = (
            <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
                <p className="font-pnsb font-size-24">Session does not exist.</p>
                <ErrorImg className="error-img" />
            </div>
        )
    } else {
        if (data) {
            toRender = (
                <Container fluid>
                    <Row lg={2} md={2} sm={1} xs={1}>
                        <Col className="h-100">
                            <div>
                                <h5 className="font-pnr mb-4">{data.merchant?.name || "Edain Pay"}</h5>
                                <p className="font-pnsb font-blue mb-0">Amount remaining:</p>
                                <div className="d-flex flex-column amount">
                                    <p className="font-pnr mb-0 amount-num">{data.amount_left < 0 ? Number(0).toFixed(18) : data.amount_left.toFixed(18)}</p>
                                    <p className="font-pnr">{data.currency_2}</p>
                                </div>

                                <p className="font-pnsb font-blue mb-0">Amount paid:</p>
                                <div className="d-flex flex-column amount">
                                    <p className="font-pnr mb-0 amount-num">{data.amount_paid.toFixed(18)}</p>
                                    <p className="font-pnr">{data.currency_2}</p>
                                </div>

                                <p className="font-pnsb font-blue mb-0">Total amount:</p>
                                <div className="d-flex flex-column amount">
                                    <p className="font-pnr mb-0 amount-num">{data.amount_total.toFixed(18)}</p>
                                    <p className="font-pnr">{data.currency_2}</p>
                                </div>

                                <p className="font-pnsb font-blue mb-0">Status:</p>
                                <div className="d-flex flex-column amount">
                                    <p className="font-pnr">{displayStatus()}</p>
                                </div>
                            </div>
                            <div className="divider-container-lg">
                                <img src={Logo} className="logo" alt="edain-pay" />
                                <b className="hr anim"></b>
                                <div className="d-flex align-items-center gap-2">
                                    <p onClick={() => window.open("https://edain.ai/privacy-policy", "_blank")} className="font-pnb cursor-pointer">Privacy Policy</p>
                                    <p className="font-pnb">|</p>
                                    <p onClick={() => window.open("https://edain.ai/terms", "_blank")} className="font-pnb cursor-pointer">Terms and Conditions</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className="qr-container mb-3">
                                <QRCode size={150} value={data.wallet?.address} />
                            </div>

                            <p className="font-pnsb font-blue mb-0">Address:</p>
                            <OverlayTrigger placement="top" overlay={copyToClipboardTP}>
                                <div onClick={() => handleCopyToClipboard(data.wallet?.address)} className="cursor-pointer d-flex mb-3">
                                    <input readOnly className="primary-input input-border-radius" type="text" value={data.wallet?.address} />
                                    <div className="copy-to-clipboard-extension"><CopyToClipboardIcon /></div>
                                </div>
                            </OverlayTrigger>

                            {data.status === "paid" ? (
                                <input readOnly className="w-100 primary-input mb-3 success-color" type="text" value="Transaction completed" />
                            ) : (
                                <div className="mb-3">
                                    <p className="font-pnsb font-blue mb-0">Time left:</p>
                                    <Countdown date={data.expires_at_soft} renderer={renderer} />
                                </div>
                            )}

                            <p className="font-pnsb font-blue mb-0">Payment ID:</p>
                            <input readOnly className="w-100 primary-input mb-3" type="text" value={data.id} />

                            <p className="font-pnsb font-blue mb-0">Verification Code:</p>
                            <input readOnly className="w-100 primary-input mb-3" type="text" value={data.client_reference_id || ""} />
                            <p className="font-pnl font-size-14 font-grey">(save in case there are any issues with your payment)</p>

                            <div className="divider-container-sm">
                                <b className="hr anim"></b>
                                <img src={Logo} className="logo mb-2" alt="edain-pay" />
                                <div className="d-flex flex-column mt-1">
                                    <p onClick={() => window.open("https://edain.ai/privacy-policy", "_blank")} className="font-pnb mb-1 cursor-pointer">Privacy Policy</p>
                                    <p onClick={() => window.open("https://edain.ai/terms", "_blank")} className="font-pnb cursor-pointer">Terms and Conditions</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )
        } else {
            toRender = (
                <div className="error my-3 d-flex align-items-center justify-content-center flex-column">
                    <p className="font-pnsb font-size-24">Oops.. There was an error!</p>
                    <ErrorImg className="error-img" />
                </div>
            );
        }
    }

    return (
        <Container>
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <div className="main-box payment my-3 p-5 d-flex flex-column justify-content-center align-items-center h-100">
                    <div className="transaction-status-header pb-4 d-flex justify-content-center align-items-center">
                        <p className="font-pnsb font-size-24 text-align-center">
                            Transaction Status
                        </p>
                    </div>
                    {toRender}
                </div>
            </div>
        </Container>
    );
};

export default TransactionStatus;