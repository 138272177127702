import React from 'react';
import { Modal } from "react-bootstrap";
import HashLoader from "react-spinners/HashLoader";
import './RefundModal.scss';

const RefundModal = ({ show, onHide, walletAddress, handleRefund, amount, currency, loadingRefund }) => {
    return (
        <Modal centered show={show} onHide={onHide} id="refund-modal" className="text-white">
            <Modal.Header>
                <div className="w-100 d-flex justify-content-center">
                    <h5 className="text-center font-pnb">Withdraw {currency} confirmation</h5>
                </div>
            </Modal.Header>
            <Modal.Body>
                {loadingRefund ? (
                    <div className="d-flex justify-content-center align-items-center h-100 w-100">
                        <HashLoader size={100} color={"#45C2D5"} loading={loadingRefund} />
                    </div>
                ) : (
                    <>
                        <p className="text-center font-pnr">
                            <span className="font-blue font-pnb eai-amount">Amount:</span> {amount.toFixed(18)} {currency}
                        </p>

                        <p className="text-center font-pnr">
                            Are you sure you want to refund {currency} using this wallet address?
                        </p>

                        <div className="address-container">
                            <p className="text-break mb-0 font-pnr">{walletAddress}</p>
                        </div>

                        <div className="mt-4 d-flex justify-content-center gap-2">
                            <button className="epay-blue-button" onClick={handleRefund}>
                                Confirm
                            </button>
                            <button className="epay-white-button" onClick={onHide}>
                                Cancel
                            </button>
                        </div>
                    </>
                )
                }
            </Modal.Body >
        </Modal >
    )
}

export default RefundModal;