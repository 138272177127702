import React from 'react';
import './Error.scss';
import { Container } from 'react-bootstrap';
import { ReactComponent as ErrorImg } from "../../Assets/SVGs/undraw_404.svg";

const Payment = () => {
    return (
        <Container>
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <div className="main-box error my-3 p-5 d-flex align-items-center justify-content-center flex-column">
                    <p className="font-pnsb font-size-64">404</p>
                    <p className="font-pnr">Sorry, we can't find that page!</p>
                    <ErrorImg className="error-img" />
                </div>
            </div>
        </Container>
    );
};

export default Payment;