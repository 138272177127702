import { Routes, Route } from "react-router-dom";
import "./Template/main.scss";
import "react-toastify/dist/ReactToastify.css";

import { routes } from "./Utils/routes";
import { ToastContainer } from "react-toastify";

import Payment from "./Views/Payment/Payment";
import TransactionStatus from "./Views/TransactionStatus/TransactionStatus";
import Refund from "./Views/Refund/Refund";
import Error from "./Views/Error/Error";

// TODO refund page
// TODO user agreement and privacy policy pages

const App = () => {
   return (
      <div className="App">
         <ToastContainer
            position="top-center"
            autoClose={5000}
            // autoClose={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            closeButton={true}
         />

         <Routes>
            <Route path={routes.payment} element={<Payment />} />
            <Route path={routes.status} element={<TransactionStatus />} />
            <Route path={routes.refund} element={<Refund />} />
            <Route path="*" element={<Error />} />
         </Routes>
      </div>
   );
};

export default App;
